<template>
  <div class="addlabel">
    <div class="essential_information">
      <div class="form_essential">
        <el-form ref="form" :model="form" :rules="rules" label-width="100px">
          <div>
            <el-form-item label="标签组名称:" prop="groupLabelName">
              <el-input
                v-model="form.groupLabelName"
                placeholder="请输入标签组名称"
              />
            </el-form-item>

            <el-form-item label="标签组前缀:" prop="groupLabelPrefix">
              <el-input
                v-model="form.groupLabelPrefix"
                placeholder="请输入标签组前缀"
              />
            </el-form-item>

            <el-form-item label="标签组层级:" prop="groupLabelLevel">
              <el-radio-group v-model="form.groupLabelLevel">
                <el-radio
                  v-for="item in LabelLevel"
                  :key="item.id"
                  :label="item.id"
                  @change="levelchange"
                  >{{ item.val }}</el-radio
                >
              </el-radio-group>
              <el-button
                style="margin-left: 10px"
                type="primary"
                size="mini"
                @click="editlevel"
                >编辑位数</el-button
              >
            </el-form-item>
            <el-form-item label="标签组类别:" prop="groupLabelType">
              <el-radio-group
                style="position: relative; top: 4px"
                v-model="form.groupLabelType"
              >
                <el-radio
                  style="padding: 10px 0px"
                  v-for="(item, index) in grouplabel"
                  :key="index"
                  :label="item.labeltype"
                  >{{ item.labeltype }}</el-radio
                >
                <el-button
                  style="margin-left: 10px"
                  type="primary"
                  size="mini"
                  @click="editlabel"
                  >编辑类别</el-button
                >
              </el-radio-group>
            </el-form-item>
            <el-form-item label="标签组权重:" prop="groupLabelPrefix">
              <div class="num_input">
                <el-input-number
                  v-model="form.groupLabelWeight"
                  :min="1"
                  :step="0.1"
                  :max="10"
                  label="请输入权重"
                ></el-input-number>
                <span style="color: #9b9a9a"
                  >权重区间是1-10，可到小数点后一位，例:1.6</span
                >
              </div>
            </el-form-item>
            <el-form-item label="标签组描述:" prop="partnerIntro">
              <el-input
                v-model="form.groupLabelDescribe"
                type="textarea"
                placeholder="请输入标签组描述"
              />
            </el-form-item>

            <el-form-item label="标签组:" prop="">
              <div
                class="flex_box"
                v-for="(item1, index1) in form.labelParamList"
                :key="index1"
                style="padding: 10px 0 0 0px"
              >
                <div class="general_inputs">
                  <el-form-item label="一级：" label-width="100px">
                    <div class="input_flex">
                      <el-input
                        v-model="item1.labelName"
                        size="small"
                      ></el-input>
                      <div class="number_color">
                        <el-tooltip
                          v-if="item1.id"
                          class="item"
                          effect="dark"
                          :content="item1.id"
                          placement="top-start"
                        >
                          <span class="label_number" style="font-size: 12px">{{
                            item1.id
                          }}</span>
                        </el-tooltip>

                        <span style="font-size: 12px" v-else>编号展示区域</span>
                      </div>
                      <el-dropdown>
                        <span class="el-dropdown-link" style="cursor: pointer">
                          <i class="el-icon-circle-plus-outline"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item
                            v-for="(el, i) in levelabelone"
                            :key="i"
                            @click.native="addlabel1(index1)"
                            >{{ el.val }}</el-dropdown-item
                          >
                        </el-dropdown-menu>
                      </el-dropdown>
                      <span
                        v-if="id"
                        class="el_inco"
                        @click="addLabelList1(item1, index1)"
                      >
                        <i class="el-icon-arrow-up"></i>
                      </span>
                    </div>
                  </el-form-item>

                  <div
                    v-for="(item2, index2) in item1.labelParamChild"
                    :key="index2"
                    style="padding-left: 100px"
                  >
                    <el-form-item label="二级：" label-width="80px">
                      <div class="input_flex">
                        <el-input
                          v-model="item2.labelName"
                          size="small"
                        ></el-input>
                        <div class="number_color">
                          <el-tooltip
                            v-if="item2.id"
                            class="item"
                            effect="dark"
                            :content="item2.id"
                            placement="top-start"
                          >
                            <span
                              class="label_number"
                              style="font-size: 12px"
                              >{{ item2.id }}</span
                            >
                          </el-tooltip>
                          <span style="font-size: 12px" v-else
                            >编号展示区域</span
                          >
                        </div>
                        <el-dropdown>
                          <span
                            class="el-dropdown-link"
                            style="cursor: pointer"
                          >
                            <i class="el-icon-circle-plus-outline"></i>
                          </span>
                          <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item
                              v-for="(el, i) in levelabeltwo"
                              :key="i"
                              @click.native="
                                addlabel2(index1, index2, el.num, i)
                              "
                              >{{ el.val }}</el-dropdown-item
                            >
                          </el-dropdown-menu>
                        </el-dropdown>
                        <span v-if="!id">
                          <i
                            @click="delet2(index1, index2)"
                            class="el-icon-remove-outline"
                          ></i>
                        </span>
                        <span v-if="id != null && item2.id == null">
                          <i
                            @click="delet2(index1, index2)"
                            class="el-icon-remove-outline"
                          ></i>
                        </span>
                        <span
                          v-if="id"
                          class="el_inco"
                          @click="addLabelList2(item2, index1, index2)"
                        >
                          <i class="el-icon-arrow-up"></i>
                        </span>
                      </div>
                    </el-form-item>

                    <div
                      v-for="(item3, index3) in item2.labelParamChild"
                      :key="index3"
                      style="padding-left: 100px"
                    >
                      <el-form-item label="三级：" label-width="80px">
                        <div class="input_flex">
                          <el-input
                            v-model="item3.labelName"
                            size="small"
                          ></el-input>
                          <div class="number_color">
                            <el-tooltip
                              v-if="item3.id"
                              class="item"
                              effect="dark"
                              :content="item3.id"
                              placement="top-start"
                            >
                              <span
                                class="label_number"
                                style="font-size: 12px"
                                >{{ item3.id }}</span
                              >
                            </el-tooltip>
                            <span style="font-size: 12px" v-else
                              >编号展示区域</span
                            >
                          </div>
                          <el-dropdown>
                            <span
                              class="el-dropdown-link"
                              style="cursor: pointer"
                            >
                              <i class="el-icon-circle-plus-outline"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                              <el-dropdown-item
                                v-for="(el, i) in levelabelthree"
                                :key="i"
                                @click.native="
                                  addlabel3(index1, index2, index3, el.num, i)
                                "
                                >{{ el.val }}</el-dropdown-item
                              >
                              <!-- <el-dropdown-item @click.native="addlabel2(index1, index2, 'all')"
                                  >添加所有下级</el-dropdown-item
                                > -->
                            </el-dropdown-menu>
                          </el-dropdown>
                          <span v-if="!id">
                            <i
                              @click="delet3(index1, index2, index3)"
                              class="el-icon-remove-outline"
                            ></i>
                          </span>
                          <span v-if="id != null && item3.id == null">
                            <i
                              @click="delet3(index1, index2, index3)"
                              class="el-icon-remove-outline"
                            ></i>
                          </span>
                          <span
                            v-if="id"
                            class="el_inco"
                            @click="
                              addLabelList3(item3, index1, index2, index3)
                            "
                          >
                            <i class="el-icon-arrow-up"></i>
                          </span>
                        </div>
                      </el-form-item>

                      <div
                        v-for="(item4, index4) in item3.labelParamChild"
                        :key="index4"
                        style="padding-left: 100px"
                      >
                        <el-form-item label="四级：" label-width="80px">
                          <div class="input_flex">
                            <el-input
                              v-model="item4.labelName"
                              size="small"
                            ></el-input>
                            <div class="number_color">
                              <el-tooltip
                                v-if="item4.id"
                                class="item"
                                effect="dark"
                                :content="item4.id"
                                placement="top-start"
                              >
                                <span
                                  class="label_number"
                                  style="font-size: 12px"
                                  >{{ item4.id }}</span
                                >
                              </el-tooltip>
                              <span style="font-size: 12px" v-else
                                >编号展示区域</span
                              >
                            </div>
                            <el-dropdown>
                              <span
                                class="el-dropdown-link"
                                style="cursor: pointer"
                              >
                                <i class="el-icon-circle-plus-outline"></i>
                              </span>
                              <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item
                                  v-for="(el, i) in levelabelfour"
                                  :key="i"
                                  @click.native="
                                    addlabel4(
                                      index1,
                                      index2,
                                      index3,
                                      index4,
                                      el.num,
                                      i
                                    )
                                  "
                                  >{{ el.val }}</el-dropdown-item
                                >
                                <!-- <el-dropdown-item
                                    @click.native="addFour(index1, index2, index3, 'all')"
                                    >添加所有下级</el-dropdown-item
                                  > -->
                              </el-dropdown-menu>
                            </el-dropdown>
                            <span v-if="!id">
                              <i
                                @click="delet4(index1, index2, index3, index4)"
                                class="el-icon-remove-outline"
                              ></i>
                            </span>
                            <span v-if="id != null && item4.id == null">
                              <i
                                @click="delet4(index1, index2, index3, index4)"
                                class="el-icon-remove-outline"
                              ></i>
                            </span>
                            <span
                              v-if="id"
                              class="el_inco"
                              @click="
                                addLabelList4(
                                  item4,
                                  index1,
                                  index2,
                                  index3,
                                  index4
                                )
                              "
                            >
                              <i class="el-icon-arrow-up"></i>
                            </span>
                          </div>
                        </el-form-item>

                        <div
                          v-for="(item5, index5) in item4.labelParamChild"
                          :key="index5"
                          style="padding-left: 100px"
                        >
                          <el-form-item label="五级：" label-width="80px">
                            <div class="input_flex">
                              <el-input
                                v-model="item5.labelName"
                                size="small"
                              ></el-input>
                              <div class="number_color">
                                <el-tooltip
                                  v-if="item5.id"
                                  class="item"
                                  effect="dark"
                                  :content="item5.id"
                                  placement="top-start"
                                >
                                  <span
                                    class="label_number"
                                    style="font-size: 12px"
                                    >{{ item5.id }}</span
                                  >
                                </el-tooltip>
                                <span style="font-size: 12px" v-else
                                  >编号展示区域</span
                                >
                              </div>
                              <el-dropdown>
                                <span
                                  class="el-dropdown-link"
                                  style="cursor: pointer"
                                >
                                  <i class="el-icon-circle-plus-outline"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                  <el-dropdown-item
                                    v-for="(el, i) in levelabelfive"
                                    :key="i"
                                    @click.native="
                                      addlabel5(
                                        index1,
                                        index2,
                                        index3,
                                        index4,
                                        index5,
                                        el.num,
                                        i
                                      )
                                    "
                                    >{{ el.val }}</el-dropdown-item
                                  >
                                </el-dropdown-menu>
                              </el-dropdown>
                              <span v-if="!id">
                                <i
                                  @click="
                                    delet5(
                                      index1,
                                      index2,
                                      index3,
                                      index4,
                                      index5
                                    )
                                  "
                                  class="el-icon-remove-outline"
                                ></i>
                              </span>
                              <span v-if="id != null && item5.id == null">
                                <i
                                  @click="
                                    delet5(
                                      index1,
                                      index2,
                                      index3,
                                      index4,
                                      index5
                                    )
                                  "
                                  class="el-icon-remove-outline"
                                ></i>
                              </span>
                              <span
                                v-if="id"
                                class="el_inco"
                                @click="
                                  addLabelList5(
                                    item5,
                                    index1,
                                    index2,
                                    index3,
                                    index4,
                                    index5
                                  )
                                "
                              >
                                <i class="el-icon-arrow-up"></i>
                              </span>
                            </div>
                          </el-form-item>
                          <div
                            v-for="(item6, index6) in item5.labelParamChild"
                            :key="index6"
                            style="padding-left: 100px"
                          >
                            <el-form-item label="六级：" label-width="80px">
                              <div class="input_flex">
                                <el-input
                                  v-model="item6.labelName"
                                  size="small"
                                ></el-input>
                                <div class="number_color">
                                  <el-tooltip
                                    v-if="item6.id"
                                    class="item"
                                    effect="dark"
                                    :content="item6.id"
                                    placement="top-start"
                                  >
                                    <span
                                      class="label_number"
                                      style="font-size: 12px"
                                      >{{ item6.id }}</span
                                    >
                                  </el-tooltip>
                                  <span style="font-size: 12px" v-else
                                    >编号展示区域</span
                                  >
                                </div>
                                <el-dropdown>
                                  <span
                                    class="el-dropdown-link"
                                    style="cursor: pointer"
                                  >
                                    <i class="el-icon-circle-plus-outline"></i>
                                  </span>
                                  <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item
                                      v-for="(el, i) in levelabelsix"
                                      :key="i"
                                      @click.native="
                                        addlabel6(
                                          index1,
                                          index2,
                                          index3,
                                          index4,
                                          index5,
                                          index6,
                                          el.num,
                                          i
                                        )
                                      "
                                      >{{ el.val }}</el-dropdown-item
                                    >
                                  </el-dropdown-menu>
                                </el-dropdown>
                                <span v-if="!id">
                                  <i
                                    @click="
                                      delet6(
                                        index1,
                                        index2,
                                        index3,
                                        index4,
                                        index5,
                                        index6
                                      )
                                    "
                                    class="el-icon-remove-outline"
                                  ></i>
                                </span>
                                <span v-if="id != null && item6.id == null">
                                  <i
                                    @click="
                                      delet6(
                                        index1,
                                        index2,
                                        index3,
                                        index4,
                                        index5,
                                        index6
                                      )
                                    "
                                    class="el-icon-remove-outline"
                                  ></i>
                                </span>
                                <span
                                  v-if="id"
                                  class="el_inco"
                                  @click="
                                    addLabelList6(
                                      item6,
                                      index1,
                                      index2,
                                      index3,
                                      index4,
                                      index5,
                                      index6
                                    )
                                  "
                                >
                                  <i class="el-icon-arrow-up"></i>
                                </span>
                              </div>
                            </el-form-item>
                            <div
                              v-for="(item7, index7) in item6.labelParamChild"
                              :key="index7"
                              style="padding-left: 100px"
                            >
                              <el-form-item label="七级：" label-width="80px">
                                <div class="input_flex">
                                  <el-input
                                    v-model="item7.labelName"
                                    size="small"
                                  ></el-input>
                                  <div class="number_color">
                                    <el-tooltip
                                      v-if="item7.id"
                                      class="item"
                                      effect="dark"
                                      :content="item7.id"
                                      placement="top-start"
                                    >
                                      <span
                                        class="label_number"
                                        style="font-size: 12px"
                                        >{{ item7.id }}</span
                                      >
                                    </el-tooltip>
                                    <span style="font-size: 12px" v-else
                                      >编号展示区域</span
                                    >
                                  </div>
                                  <el-dropdown>
                                    <span
                                      class="el-dropdown-link"
                                      style="cursor: pointer"
                                    >
                                      <i
                                        class="el-icon-circle-plus-outline"
                                      ></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                      <el-dropdown-item
                                        v-for="(el, i) in levelabelseven"
                                        :key="i"
                                        @click.native="
                                          addlabel7(
                                            index1,
                                            index2,
                                            index3,
                                            index4,
                                            index5,
                                            index6,
                                            index7,
                                            el.num,
                                            i
                                          )
                                        "
                                        >{{ el.val }}</el-dropdown-item
                                      >
                                    </el-dropdown-menu>
                                  </el-dropdown>
                                  <span v-if="!id">
                                    <i
                                      @click="
                                        delet7(
                                          index1,
                                          index2,
                                          index3,
                                          index4,
                                          index5,
                                          index6,
                                          index7
                                        )
                                      "
                                      class="el-icon-remove-outline"
                                    ></i>
                                  </span>
                                  <span v-if="id != null && item7.id == null">
                                    <i
                                      @click="
                                        delet7(
                                          index1,
                                          index2,
                                          index3,
                                          index4,
                                          index5,
                                          index6,
                                          index7
                                        )
                                      "
                                      class="el-icon-remove-outline"
                                    ></i>
                                  </span>
                                  <span
                                    v-if="id"
                                    class="el_inco"
                                    @click="
                                      addLabelList7(
                                        item7,
                                        index1,
                                        index2,
                                        index3,
                                        index4,
                                        index5,
                                        index6,
                                        index7
                                      )
                                    "
                                  >
                                    <i class="el-icon-arrow-up"></i>
                                  </span>
                                </div>
                              </el-form-item>
                              <div
                                v-for="(item8, index8) in item7.labelParamChild"
                                :key="index8"
                                style="padding-left: 100px"
                              >
                                <el-form-item label="八级：" label-width="80px">
                                  <div class="input_flex">
                                    <el-input
                                      v-model="item8.labelName"
                                      size="small"
                                    ></el-input>
                                    <div class="number_color">
                                      <el-tooltip
                                        v-if="item8.id"
                                        class="item"
                                        effect="dark"
                                        :content="item8.id"
                                        placement="top-start"
                                      >
                                        <span
                                          class="label_number"
                                          style="font-size: 12px"
                                          >{{ item8.id }}</span
                                        >
                                      </el-tooltip>
                                      <span style="font-size: 12px" v-else
                                        >编号展示区域</span
                                      >
                                    </div>
                                    <el-dropdown>
                                      <span
                                        class="el-dropdown-link"
                                        style="cursor: pointer"
                                      >
                                        <i
                                          class="el-icon-circle-plus-outline"
                                        ></i>
                                      </span>
                                      <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item
                                          v-for="(el, i) in levelabeleight"
                                          :key="i"
                                          @click.native="
                                            addlabel8(item7, item8, el.num, i)
                                          "
                                          >{{ el.val }}</el-dropdown-item
                                        >
                                      </el-dropdown-menu>
                                    </el-dropdown>
                                    <span v-if="!id">
                                      <i
                                        @click="delet8(item7, item8, index8)"
                                        class="el-icon-remove-outline"
                                      ></i>
                                    </span>
                                    <span v-if="id != null && item8.id == null">
                                      <i
                                        @click="delet8(item7, item8, index8)"
                                        class="el-icon-remove-outline"
                                      ></i>
                                    </span>
                                    <span
                                      v-if="id"
                                      class="el_inco"
                                      @click="
                                        addLabelList8(
                                          item8,
                                          index1,
                                          index2,
                                          index3,
                                          index4,
                                          index5,
                                          index6,
                                          index7,
                                          index8
                                        )
                                      "
                                    >
                                      <i class="el-icon-arrow-up"></i>
                                    </span>
                                  </div>
                                </el-form-item>
                                <div
                                  v-for="(item9,
                                  index9) in item8.labelParamChild"
                                  :key="index9"
                                  style="padding-left: 100px"
                                >
                                  <el-form-item
                                    label="九级："
                                    label-width="80px"
                                  >
                                    <div class="input_flex">
                                      <el-input
                                        v-model="item9.labelName"
                                        size="small"
                                      ></el-input>
                                      <div class="number_color">
                                        <el-tooltip
                                          v-if="item9.id"
                                          class="item"
                                          effect="dark"
                                          :content="item9.id"
                                          placement="top-start"
                                        >
                                          <span
                                            class="label_number"
                                            style="font-size: 12px"
                                            >{{ item9.id }}</span
                                          >
                                        </el-tooltip>
                                        <span style="font-size: 12px" v-else
                                          >编号展示区域</span
                                        >
                                      </div>
                                      <el-dropdown>
                                        <span
                                          class="el-dropdown-link"
                                          style="cursor: pointer"
                                        >
                                          <i
                                            class="el-icon-circle-plus-outline"
                                          ></i>
                                        </span>
                                        <el-dropdown-menu slot="dropdown">
                                          <el-dropdown-item
                                            v-for="(el, i) in levelabelnine"
                                            :key="i"
                                            @click.native="
                                              addlabel9(
                                                index1,
                                                index2,
                                                index3,
                                                index4,
                                                index5,
                                                index6,
                                                index7,
                                                index8,
                                                index9,
                                                el.num,
                                                i
                                              )
                                            "
                                            >{{ el.val }}</el-dropdown-item
                                          >
                                        </el-dropdown-menu>
                                      </el-dropdown>
                                      <span v-if="!id">
                                        <i
                                          @click="
                                            delet9(
                                              index1,
                                              index2,
                                              index3,
                                              index4,
                                              index5,
                                              index6,
                                              index7,
                                              index8,
                                              index9
                                            )
                                          "
                                          class="el-icon-remove-outline"
                                        ></i>
                                      </span>
                                      <span
                                        v-if="id != null && item9.id == null"
                                      >
                                        <i
                                          @click="
                                            delet9(
                                              index1,
                                              index2,
                                              index3,
                                              index4,
                                              index5,
                                              index6,
                                              index7,
                                              index8,
                                              index9
                                            )
                                          "
                                          class="el-icon-remove-outline"
                                        ></i>
                                      </span>
                                      <span
                                        v-if="id"
                                        class="el_inco"
                                        @click="
                                          addLabelList9(
                                            item9,
                                            index1,
                                            index2,
                                            index3,
                                            index4,
                                            index5,
                                            index6,
                                            index7,
                                            index8,
                                            index9
                                          )
                                        "
                                      >
                                        <i class="el-icon-arrow-up"></i>
                                      </span>
                                    </div>
                                  </el-form-item>
                                  <div
                                    v-for="(item10,
                                    index10) in item9.labelParamChild"
                                    :key="index10"
                                    style="padding-left: 100px"
                                  >
                                    <el-form-item
                                      label="十级："
                                      label-width="80px"
                                    >
                                      <div class="input_flex">
                                        <el-input
                                          v-model="item10.labelName"
                                          size="small"
                                        ></el-input>
                                        <div class="number_color">
                                          <el-tooltip
                                            v-if="item10.id"
                                            class="item"
                                            effect="dark"
                                            :content="item10.id"
                                            placement="top-start"
                                          >
                                            <span
                                              class="label_number"
                                              style="font-size: 12px"
                                              >{{ item10.id }}</span
                                            >
                                          </el-tooltip>
                                          <span style="font-size: 12px" v-else
                                            >编号展示区域</span
                                          >
                                        </div>
                                        <i
                                          @click="
                                            addlabel10(
                                              index1,
                                              index2,
                                              index3,
                                              index4,
                                              index5,
                                              index6,
                                              index7,
                                              index8,
                                              index9
                                            )
                                          "
                                          class="el-icon-circle-plus-outline"
                                        ></i>
                                        <span v-if="!id">
                                          <i
                                            @click="
                                              delet10(
                                                index1,
                                                index2,
                                                index3,
                                                index4,
                                                index5,
                                                index6,
                                                index7,
                                                index8,
                                                index9,
                                                index10
                                              )
                                            "
                                            class="el-icon-remove-outline"
                                          ></i>
                                        </span>
                                      </div>
                                    </el-form-item>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div>
                  <span @click="addlabel" class="el-dropdown-link" style="cursor: pointer">
                    <i class="el-icon-circle-plus-outline"></i>
                  </span>
                  <span v-if="index1 > 0" @click="delet1(index1)">
                    <i class="el-icon-remove-outline"></i>
                  </span>
                </div> -->
              </div>
            </el-form-item>
            <el-form-item label="上级标签组:" prop="informationCompanies">
              <div
                class="input_flex"
                style="display: flex"
                v-for="(item, index) in superior"
                :key="index"
              >
                <div>
                  <div class="name_form" style="padding-bottom: 10px">
                    <el-autocomplete
                      v-model="item.labelName"
                      :fetch-suggestions="
                        (res, cb) => {
                          return querySearchAsyncapts(res, cb, index);
                        }
                      "
                      placeholder="请输入标签组名称搜索"
                      @select="
                        res => {
                          return handleSelectcapts(res, index);
                        }
                      "
                    >
                      <template slot-scope="{ item }">
                        <div class="name">
                          <span class="addr">{{ item.groupLabelName }}</span>
                        </div>
                      </template>
                    </el-autocomplete>
                  </div>
                  <div class="name_form" style="padding-bottom: 10px">
                    <el-autocomplete
                      v-model="item.label"
                      :fetch-suggestions="
                        (res, cb) => {
                          return querySearchAsyncaptlabel(res, cb, index);
                        }
                      "
                      placeholder="请输入标签组对应的标签名称搜索"
                      @select="
                        res => {
                          return handleSelectcaptlabel(res, index);
                        }
                      "
                    >
                      <template slot-scope="{ item }">
                        <div class="name">
                          <span class="addr">{{ item.labelName }}</span>
                        </div>
                      </template>
                    </el-autocomplete>
                  </div>
                </div>
                <div style="display: flex; font-size: 24px; margin-left: 10px">
                  <span
                    v-if="index == 0"
                    style="color: #4e93fb"
                    @click="addenterprise"
                    ><i class="el-icon-circle-plus"></i
                  ></span>
                  <span
                    v-if="index > 0"
                    style="color: #ff7575"
                    @click="delenterprise(index)"
                    ><i class="el-icon-delete"></i
                  ></span>
                </div>
              </div>
            </el-form-item>

            <!-- 新增应用模块字段 -->
            <el-form-item label="应用模块" prop="applicationModules">
              <el-checkbox
                :indeterminate="isIndeterminate"
                v-model="isCheckAllApps"
                @change="handleCheckAllChange"
                >全选</el-checkbox
              >

              <el-checkbox-group
                v-model="form.applicationModules"
                @change="handleCheckedAppsChange"
              >
                <el-checkbox
                  v-for="(menu, menu_ind) in menuTypeOptions"
                  :label="menu.value"
                  :key="menu_ind"
                  >{{ menu.label }}</el-checkbox
                >
              </el-checkbox-group>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div style="margin-top: 30px" class="dialog-footer">
        <el-button type="primary" @click="submitForm">保存</el-button>
        <el-button @click="clcans">取消</el-button>
      </div>
    </div>
    <el-dialog
      title="编辑位数"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="submitlevel"
    >
      <div class="po_relive">
        <el-form label-width="80px">
          <div v-for="(item, index) in levelist" :key="index">
            <el-form-item :label="`${index + 1}级位数`">
              <el-input
                placeholder="请输入数字"
                v-model="item.num"
                type="number"
              >
              </el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitlevel('save')">确 定</el-button>
        <el-button type="primary" plain @click="submitlevel">取 消</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="编辑类别"
      :visible.sync="dialoglabel"
      width="60%"
      :before-close="submitlabel"
    >
      <el-form label-width="130px">
        <div class="label_flex">
          <div v-for="(item, index) in grouplabel" :key="index">
            <el-form-item :label="`标签组类别${index + 1}`">
              <el-input placeholder="请输入标签类别" v-model="item.labeltype">
              </el-input>
            </el-form-item>
          </div>
        </div>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitlabel('save')">确 定</el-button>
        <el-button type="primary" plain @click="submitlabel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  addlabel,
  updateLabel,
  selectLabelDetail,
  getAssociateGroupLabel,
  getLabelList,
  getGroupLabel,
  getAssociateLabel,
  menuTypeOptionsApi
} from "@/api/label";
export default {
  name: "addlabel",
  computed: {
    id() {
      return this.$route.query.id;
    }
  },
  data() {
    return {
      isIndeterminate: false,
      isCheckAllApps: false,
      menuTypeOptions: [], //应用模块option
      dialogVisible: false, //编辑位数弹窗
      dialoglabel: false, //编辑类别弹窗
      grouplabel: [], //标签组类别
      levelist: [], //编辑位数
      superior: [
        {
          groupLabelPrefix: "",
          id: "",
          labelName: "",
          label: ""
        }
      ], //上级标签
      superiorList: [], //上级标签组数组
      form: {
        associateLabel: "", //标签和标签之间的关联
        groupLabelDescribe: null, //标签组描述
        groupLabelType: "",
        groupLabelLevel: 1, //标签组最大层级
        groupLabelName: null, //标签组名字
        groupLabelPrefix: null, //标签组前缀
        groupLabelWeight: null, //标签权重
        levelDigit: "", //标签组层级每一层对应的编号位数
        id: null,
        isDelete: null, //冻结；1标识冻结，0是正常
        labelParamList: [
          {
            associateId: "", //标签之间的关联
            id: null,
            labelName: null,
            labelParamChild: []
          }
        ],
        applicationModules: [] //应用模块
      },
      rules: {
        groupLabelName: [
          { required: true, message: "请输入标签组名字", trigger: "blur" }
        ],
        groupLabelLevel: [
          { required: true, message: "请输入标签组最大层级", trigger: "blur" }
        ],
        groupLabelPrefix: [
          { required: true, message: "请输入标签组前缀", trigger: "blur" }
        ],
        groupLabelWeight: [
          { required: true, message: "请输入标签权重", trigger: "blur" }
        ],
        groupLabelType: [
          { required: true, message: "请输入标签组类别", trigger: "blur" }
        ],
        // 应用模块
        applicationModules: [
          { required: true, message: "请选择应用模块", trigger: "change" }
        ]
      },
      LabelLevel: [
        {
          id: 1,
          val: "一级标签"
        },
        {
          id: 2,
          val: "二级标签"
        },
        {
          id: 3,
          val: "三级标签"
        },
        {
          id: 4,
          val: "四级标签"
        },
        {
          id: 5,
          val: "五级标签"
        },
        {
          id: 6,
          val: "六级标签"
        },
        {
          id: 7,
          val: "七级标签"
        },
        {
          id: 8,
          val: "八级标签"
        },
        {
          id: 9,
          val: "九级标签"
        },
        {
          id: 10,
          val: "十级标签"
        }
      ],
      Level: 1,
      levelabelone: [
        {
          val: "添加二级",
          num: "two"
        }
      ],
      levelabeltwo: [
        {
          val: "添加二级",
          num: "two"
        },
        {
          val: "添加三级",
          num: "three"
        }
      ],
      levelabelthree: [
        {
          val: "添加三级",
          num: "three"
        },
        {
          val: "添加四级",
          num: "four"
        }
      ],
      levelabelfour: [
        {
          val: "添加四级",
          num: "four"
        },
        {
          val: "添加五级",
          num: "five"
        }
      ],
      levelabelfive: [
        {
          val: "添加五级",
          num: "five"
        },

        {
          val: "添加六级",
          num: "six"
        }
      ],
      levelabelsix: [
        {
          val: "添加六级",
          num: "six"
        },
        {
          val: "添加七级",
          num: "seven"
        }
      ],
      levelabelseven: [
        {
          val: "添加七级",
          num: "seven"
        },
        {
          val: "添加八级",
          num: "eight"
        }
      ],
      levelabeleight: [
        {
          val: "添加八级",
          num: "eight"
        },
        {
          val: "添加九级",
          num: "nine"
        }
      ],
      levelabelnine: [
        {
          val: "添加九级",
          num: "nine"
        },
        {
          val: "添加,十级",
          num: "ten"
        }
      ],
      labelParamChild: []
    };
  },
  created() {
    this.$nextTick(() => {
      if (this.id) {
        this.selectLabelDetail();
      }
      this.getGroupLabel();
      this.getMenuTypeOptions();
    });
  },
  methods: {
    // 判断应用模块是否全选判断
    handleAppModulesCheckAll() {
      //是否全选判断
      if (this.menuTypeOptions.length == this.form.applicationModules.length) {
        this.isCheckAllApps = true;
        this.isIndeterminate = false;
      } else if (this.form.applicationModules.length) {
        this.isIndeterminate = true;
      }
    },
    //获取应用模块标签数据
    async getMenuTypeOptions() {
      const res = await menuTypeOptionsApi();
      if (res.code == 200) {
        const list = _.map(res.data, item => ({
          label: item.codeName,
          value: item.codeId
        }));
        this.menuTypeOptions = list;
        this.handleAppModulesCheckAll();
      }
    },
    // 应用模块全选改变事件
    handleCheckAllChange(val) {
      this.form.applicationModules = val
        ? _.map(this.menuTypeOptions, o => o.value)
        : [];
      this.isIndeterminate = false;
    },
    //应用模块onchange
    handleCheckedAppsChange(value) {
      let checkedCount = value.length;
      this.isCheckAllApps = checkedCount === this.menuTypeOptions.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.menuTypeOptions.length;
    },
    //查询所有标签组类别
    async getGroupLabel() {
      let list = [];
      const res = await getGroupLabel();
      if (res.code == 200) {
        list = res.data;
        list.forEach(el => {
          this.grouplabel.push({
            labeltype: el
          });
        });
      }
    },
    //标签组类别
    editlabel() {
      this.grouplabel.push({
        labeltype: ""
      });
      this.dialoglabel = true;
    },
    //标签组类别操作
    submitlabel(str) {
      let num = this.grouplabel.length - 1;
      if (str == "save") {
        if (this.grouplabel[num].labeltype) {
          this.dialoglabel = false;
        } else {
          this.$message.warning("请输入新增标签组类别");
        }
      } else {
        this.grouplabel.splice(num, 1);
        this.dialoglabel = false;
      }
    },

    //编辑位数弹窗
    submitlevel(str) {
      if (str == "save") {
        this.dialogVisible = false;
      } else {
        this.dialogVisible = false;
        this.levelist = [];
      }
    },
    //编辑位数
    editlevel() {
      let num = this.form.groupLabelLevel * 1;
      if (!this.levelist.length) {
        for (var i = 0; i < num; i++) {
          this.levelist.push({
            num: null
          });
        }
      }

      this.dialogVisible = true;
    },
    //查询上级标签组
    async querySearchAsyncapts(queryString, cb, index) {
      if (this.superior[index].labelName) {
        const res = await getAssociateGroupLabel({
          keyword: this.superior[index].labelName
        });
        if (res.code == 200) {
          this.superiorList = res.data;
          cb(this.superiorList);
        }
      } else {
        cb(this.superiorList);
      }
    },

    //选择上级标签
    handleSelectcapts(item, index) {
      this.superior[index].groupLabelPrefix = item.groupLabelPrefix;
      this.superior[index].labelName = item.groupLabelName;
    },

    //查询上级标签组
    async querySearchAsyncaptlabel(queryString, cb, index) {
      if (this.superior[index].groupLabelPrefix) {
        const res = await getAssociateLabel({
          groupLabelPrefix: this.superior[index].groupLabelPrefix
        });
        if (res.code == 200) {
          this.superiorList = res.data;
          var restaurants = this.superiorList;
          var results = queryString
            ? restaurants.filter(this.createStateFilter(queryString))
            : restaurants;

          cb(results);
        }
      }
    },
    createStateFilter(queryString) {
      return state => {
        return (
          state.labelName.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },

    //选择上级标签
    handleSelectcaptlabel(item, index) {
      this.superior[index].id = item.id;
      this.superior[index].label = item.labelName;
    },
    //新增上级标签组
    addenterprise() {
      this.superior.push({
        groupLabelPrefix: "",
        id: "",
        labelName: "",
        label: ""
      });
    },
    //删除上级标签组
    delenterprise(index) {
      this.superior.splice(index, 1);
    },
    //标签组详情
    async selectLabelDetail() {
      const query = {
        id: this.id
      };
      const res = await selectLabelDetail(query);
      if (res.code == 200) {
        this.form = {
          ...res.data,
          applicationModules: res.data.applicationModules || []
        };
        this.form.groupLabelLevel = this.form.groupLabelLevel * 1;
        if (this.form.associateLabelVoList.length) {
          this.superior = [];
          this.form.associateLabelVoList.forEach(el => {
            this.superior.push({
              groupLabelPrefix: "",
              id: "",
              labelName: el.groupLabelName,
              label: el.labelName
            });
          });
        }

        //判断应用模块是否全选判断
        this.handleAppModulesCheckAll();
      }
    },
    //查询子集
    getLabelList(row) {
      const query = {
        id: row.id
      };
      return getLabelList(query).then(response => {
        return response.data;
      });
    },
    //回显展开标签
    async addLabelList1(row, index1) {
      if (row.id) {
        const data = await this.getLabelList(row);
        this.form.labelParamList[index1].labelParamChild = data;
      }
    },
    //回显展开标签
    async addLabelList2(row, index1, index2) {
      if (row.id) {
        const data = await this.getLabelList(row);
        this.form.labelParamList[index1].labelParamChild[
          index2
        ].labelParamChild = data;
      }
    },
    //回显展开标签
    async addLabelList3(row, index1, index2, index3) {
      if (row.id) {
        const data = await this.getLabelList(row);
        if (data.length > 0) {
          this.form.labelParamList[index1].labelParamChild[
            index2
          ].labelParamChild[index3].labelParamChild = data;
        }
      }
    },
    //回显展开标签
    async addLabelList4(row, index1, index2, index3, index4) {
      if (row.id) {
        const data = await this.getLabelList(row);
        if (data.length > 0) {
          this.form.labelParamList[index1].labelParamChild[
            index2
          ].labelParamChild[index3].labelParamChild[
            index4
          ].labelParamChild = data;
        }
      }
    },
    //回显展开标签
    async addLabelList5(row, index1, index2, index3, index4, index5) {
      if (row.id) {
        const data = await this.getLabelList(row);
        if (data.length > 0) {
          this.form.labelParamList[index1].labelParamChild[
            index2
          ].labelParamChild[index3].labelParamChild[index4].labelParamChild[
            index5
          ].labelParamChild = data;
        }
      }
    },
    //回显展开标签
    async addLabelList6(row, index1, index2, index3, index4, index5, index6) {
      if (row.id) {
        const data = await this.getLabelList(row);
        if (data.length > 0) {
          this.form.labelParamList[index1].labelParamChild[
            index2
          ].labelParamChild[index3].labelParamChild[index4].labelParamChild[
            index5
          ].labelParamChild[index6].labelParamChild = data;
        }
      }
    },
    //回显展开标签
    async addLabelList7(
      row,
      index1,
      index2,
      index3,
      index4,
      index5,
      index6,
      index7
    ) {
      if (row.id) {
        const data = await this.getLabelList(row);
        if (data.length > 0) {
          this.form.labelParamList[index1].labelParamChild[
            index2
          ].labelParamChild[index3].labelParamChild[index4].labelParamChild[
            index5
          ].labelParamChild[index6].labelParamChild[
            index7
          ].labelParamChild = data;
        }
      }
    },
    //回显展开标签
    async addLabelList8(
      row,
      index1,
      index2,
      index3,
      index4,
      index5,
      index6,
      index7,
      index8
    ) {
      if (row.id) {
        const data = await this.getLabelList(row);
        if (data.length > 0) {
          this.form.labelParamList[index1].labelParamChild[
            index2
          ].labelParamChild[index3].labelParamChild[index4].labelParamChild[
            index5
          ].labelParamChild[index6].labelParamChild[index7].labelParamChild[
            index8
          ].labelParamChild = data;
        }
      }
    },
    //回显展开标签
    async addLabelList9(
      row,
      index1,
      index2,
      index3,
      index4,
      index5,
      index6,
      index7,
      index8,
      index9
    ) {
      if (row.id) {
        const data = await this.getLabelList(row);
        if (data.length > 0) {
          this.form.labelParamList[index1].labelParamChild[
            index2
          ].labelParamChild[index3].labelParamChild[index4].labelParamChild[
            index5
          ].labelParamChild[index6].labelParamChild[index7].labelParamChild[
            index8
          ].labelParamChild[index9].labelParamChild = data;
        }
      }
    },

    //计算标签数
    levelchange(val) {
      this.form.labelParamList = [
        {
          associateId: "", //标签之间的关联
          id: null,
          labelName: null,
          labelParamChild: []
        }
      ];

      if (this.form.groupLabelLevel > 1) {
        this.Level = 1;
        this.levelchanges(
          this.form.labelParamList[0],
          this.form.groupLabelLevel * 1
        );
      }
    },
    levelchanges(rlist, levelnum) {
      this.Level++;
      for (var i = 0; i < 1; i++) {
        let children;
        if (!rlist.labelParamChild) {
          rlist.labelParamChild = [];
        }
        children = rlist.labelParamChild;
        children.push({
          associateId: "", //标签之间的关联
          id: null,
          labelName: null,
          labelParamChild: []
        });
        if (this.Level < levelnum) {
          this.levelchanges(children[0], levelnum);
        }
      }
    },
    /** 提交按钮 */
    submitForm() {
      //标签和标签之间的关联
      if (this.superior[0].id) {
        let labelist = [];
        this.superior.forEach(el => {
          labelist.push(el.id);
        });
        this.form.associateLabel = labelist.toString(",");
      }
      this.$refs["form"].validate(valid => {
        if (valid) {
          //将层级位数通过字段levelDigit进行保存
          if (this.levelist.length) {
            let list = [];
            this.levelist.forEach(el => {
              list.push(el.num);
            });
            this.form.levelDigit = list.toString();
          }
          if (this.form.id == null) {
            addlabel(this.form).then(response => {
              if (response.code == 200) {
                this.$message.success("新增成功");
                this.$router.go(-1);
              } else {
                this.$message.error(response.message);
              }
            });
          } else {
            updateLabel(this.form).then(response => {
              if (response.code == 200) {
                this.$message.success("编辑成功");
                this.$router.go(-1);
              } else {
                this.$message.error(response.message);
              }
            });
          }
        }
      });
    },

    clcans() {
      this.$router.go(-1);
    },
    //一级下标签添加
    addlabel1(index1) {
      if (!this.form.labelParamList[index1].labelParamChild) {
        this.form.labelParamList[index1].labelParamChild = [];
      }

      this.form.labelParamList[index1].labelParamChild.push({
        associateId: "", //标签之间的关联
        id: null,
        labelName: null,
        labelParamChild: []
      });
      if (this.form.groupLabelLevel == 1) {
        this.form.groupLabelLevel = this.form.groupLabelLevel + 1;
      }
    },
    addlabel() {
      this.form.labelParamList.push({
        associateId: "", //标签之间的关联
        id: null,
        labelName: null,
        labelParamChild: []
      });
    },
    //删除一级下标签
    delet1(index1) {
      this.form.labelParamList.splice(index1, 1);
    },
    //二级下标签新加
    addlabel2(index1, index2, msg, i) {
      this.Level = 1;

      if (msg != "two") {
        if (this.form.groupLabelLevel == 2) {
          this.form.groupLabelLevel = this.form.groupLabelLevel + 1;
        }
        this.levelchanges(
          this.form.labelParamList[index1].labelParamChild[index2],
          i
        );
      } else {
        this.form.labelParamList[index1].labelParamChild.push({
          associateId: "", //标签之间的关联
          id: null,
          labelName: null,
          labelParamChild: []
        });
      }
    },
    //删除二级下标签
    delet2(index1, index2) {
      let i = this.form.labelParamList[index1].labelParamChild.length;
      if (i == 1) {
        this.form.groupLabelLevel = this.form.groupLabelLevel - 1;
      }
      this.form.labelParamList[index1].labelParamChild.splice(index2, 1);
    },
    //三级下标签新加
    addlabel3(index1, index2, index3, msg, i) {
      this.Level = 1;

      if (msg != "three") {
        if (this.form.groupLabelLevel == 3) {
          this.form.groupLabelLevel = this.form.groupLabelLevel + 1;
        }
        this.levelchanges(
          this.form.labelParamList[index1].labelParamChild[index2]
            .labelParamChild[index3],
          i
        );
      } else {
        this.form.labelParamList[index1].labelParamChild[
          index2
        ].labelParamChild.push({
          associateId: "", //标签之间的关联
          id: null,
          labelName: null,
          labelParamChild: []
        });
      }
    },
    //删除3级下标签
    delet3(index1, index2, index3) {
      let i = this.form.labelParamList[index1].labelParamChild[index2]
        .labelParamChild.length;
      if (i == 1) {
        this.form.groupLabelLevel = this.form.groupLabelLevel - 1;
      }
      this.form.labelParamList[index1].labelParamChild[
        index2
      ].labelParamChild.splice(index3, 1);
    },
    //四级下标签新加
    addlabel4(index1, index2, index3, index4, msg, i) {
      this.Level = 1;

      if (msg != "four") {
        if (this.form.groupLabelLevel == 4) {
          this.form.groupLabelLevel = this.form.groupLabelLevel + 1;
        }
        this.levelchanges(
          this.form.labelParamList[index1].labelParamChild[index2]
            .labelParamChild[index3].labelParamChild[index4],
          i
        );
      } else {
        this.form.labelParamList[index1].labelParamChild[
          index2
        ].labelParamChild[index3].labelParamChild.push({
          associateId: "", //标签之间的关联
          id: null,
          labelName: null,
          labelParamChild: []
        });
      }
    },
    //删除四级下标签
    delet4(index1, index2, index3, index4) {
      let i = this.form.labelParamList[index1].labelParamChild[index2]
        .labelParamChild[index3].labelParamChild.length;
      if (i == 1) {
        this.form.groupLabelLevel = this.form.groupLabelLevel - 1;
      }
      this.form.labelParamList[index1].labelParamChild[index2].labelParamChild[
        index3
      ].labelParamChild.splice(index4, 1);
    },
    //五级下标签新加
    addlabel5(index1, index2, index3, index4, index5, msg, i) {
      this.Level = 1;

      if (msg != "five") {
        if (this.form.groupLabelLevel == 5) {
          this.form.groupLabelLevel = this.form.groupLabelLevel + 1;
        }
        this.levelchanges(
          this.form.labelParamList[index1].labelParamChild[index2]
            .labelParamChild[index3].labelParamChild[index4].labelParamChild[
            index5
          ],
          i
        );
      } else {
        this.form.labelParamList[index1].labelParamChild[
          index2
        ].labelParamChild[index3].labelParamChild[index4].labelParamChild.push({
          associateId: "", //标签之间的关联
          id: null,
          labelName: null,
          labelParamChild: []
        });
      }
    },
    //删除五级下标签
    delet5(index1, index2, index3, index4, index5) {
      let i = this.form.labelParamList[index1].labelParamChild[index2]
        .labelParamChild[index3].labelParamChild[index4].labelParamChild.length;
      if (i == 1) {
        this.form.groupLabelLevel = this.form.groupLabelLevel - 1;
      }
      this.form.labelParamList[index1].labelParamChild[index2].labelParamChild[
        index3
      ].labelParamChild[index4].labelParamChild.splice(index5, 1);
    },
    //六级下标签新加
    addlabel6(index1, index2, index3, index4, index5, index6, msg, i) {
      this.Level = 1;

      if (msg != "six") {
        if (this.form.groupLabelLevel == 6) {
          this.form.groupLabelLevel = this.form.groupLabelLevel + 1;
        }
        this.levelchanges(
          this.form.labelParamList[index1].labelParamChild[index2]
            .labelParamChild[index3].labelParamChild[index4].labelParamChild[
            index5
          ].labelParamChild[index6],
          i
        );
      } else {
        this.form.labelParamList[index1].labelParamChild[
          index2
        ].labelParamChild[index3].labelParamChild[index4].labelParamChild[
          index5
        ].labelParamChild.push({
          associateId: "", //标签之间的关联
          id: null,
          labelName: null,
          labelParamChild: []
        });
      }
    },
    //删除6级下标签
    delet6(index1, index2, index3, index4, index5, index6) {
      let i = this.form.labelParamList[index1].labelParamChild[index2]
        .labelParamChild[index3].labelParamChild[index4].labelParamChild[index5]
        .labelParamChild.length;
      if (i == 1) {
        this.form.groupLabelLevel = this.form.groupLabelLevel - 1;
      }
      this.form.labelParamList[index1].labelParamChild[index2].labelParamChild[
        index3
      ].labelParamChild[index4].labelParamChild[index5].labelParamChild.splice(
        index6,
        1
      );
    },
    //七级下标签新加
    addlabel7(index1, index2, index3, index4, index5, index6, index7, msg, i) {
      this.Level = 1;

      if (msg != "seven") {
        if (this.form.groupLabelLevel == 7) {
          this.form.groupLabelLevel = this.form.groupLabelLevel + 1;
        }
        this.levelchanges(
          this.form.labelParamList[index1].labelParamChild[index2]
            .labelParamChild[index3].labelParamChild[index4].labelParamChild[
            index5
          ].labelParamChild[index6].labelParamChild[index7],
          i
        );
      } else {
        this.form.labelParamList[index1].labelParamChild[
          index2
        ].labelParamChild[index3].labelParamChild[index4].labelParamChild[
          index5
        ].labelParamChild[index6].labelParamChild.push({
          associateId: "", //标签之间的关联
          id: null,
          labelName: null,
          labelParamChild: []
        });
      }
    },
    //删除7级下标签
    delet7(index1, index2, index3, index4, index5, index6, index7) {
      let i = this.form.labelParamList[index1].labelParamChild[index2]
        .labelParamChild[index3].labelParamChild[index4].labelParamChild[index5]
        .labelParamChild[index6].labelParamChild.length;
      if (i == 1) {
        this.form.groupLabelLevel = this.form.groupLabelLevel - 1;
      }

      this.form.labelParamList[index1].labelParamChild[index2].labelParamChild[
        index3
      ].labelParamChild[index4].labelParamChild[index5].labelParamChild[
        index6
      ].labelParamChild.splice(index7, 1);
    },
    //八级下标签新加
    addlabel8(item7, item8, msg, i) {
      this.Level = 1;

      if (msg != "eight") {
        if (this.form.groupLabelLevel == 8) {
          this.form.groupLabelLevel = this.form.groupLabelLevel + 1;
        }
        this.levelchanges(item8, i);
      } else {
        item7.labelParamChild.push({
          associateId: "", //标签之间的关联
          id: null,
          labelName: null,
          labelParamChild: []
        });
      }
    },
    //删除8级下标签
    delet8(item7, item8, index8) {
      let i = item7.labelParamChild.length;
      if (i == 1) {
        this.form.groupLabelLevel = this.form.groupLabelLevel - 1;
      }

      item7.labelParamChild.splice(index8, 1);
    },
    //九级下标签新加
    addlabel9(
      index1,
      index2,
      index3,
      index4,
      index5,
      index6,
      index7,
      index8,
      index9,
      msg,
      i
    ) {
      this.Level = 1;

      if (msg != "nine") {
        if (this.form.groupLabelLevel == 9) {
          this.form.groupLabelLevel = this.form.groupLabelLevel + 1;
        }
        this.levelchanges(
          this.form.labelParamList[index1].labelParamChild[index2]
            .labelParamChild[index3].labelParamChild[index4].labelParamChild[
            index5
          ].labelParamChild[index6].labelParamChild[index7].labelParamChild[
            index8
          ].labelParamChild[index9],
          i
        );
      } else {
        this.form.labelParamList[index1].labelParamChild[
          index2
        ].labelParamChild[index3].labelParamChild[index4].labelParamChild[
          index5
        ].labelParamChild[index6].labelParamChild[index7].labelParamChild[
          index8
        ].labelParamChild.push({
          associateId: "", //标签之间的关联
          id: null,
          labelName: null,
          labelParamChild: []
        });
      }
    },
    //删除9级下标签
    delet9(
      index1,
      index2,
      index3,
      index4,
      index5,
      index6,
      index7,
      index8,
      index9
    ) {
      let i = this.form.labelParamList[index1].labelParamChild[index2]
        .labelParamChild[index3].labelParamChild[index4].labelParamChild[index5]
        .labelParamChild[index6].labelParamChild[index7].labelParamChild[index8]
        .labelParamChild.length;
      if (i == 1) {
        this.form.groupLabelLevel = this.form.groupLabelLevel - 1;
      }

      this.form.labelParamList[index1].labelParamChild[index2].labelParamChild[
        index3
      ].labelParamChild[index4].labelParamChild[index5].labelParamChild[
        index6
      ].labelParamChild[index7].labelParamChild[index8].labelParamChild.splice(
        index9,
        1
      );
    },
    //10级下标签新加
    addlabel10(
      index1,
      index2,
      index3,
      index4,
      index5,
      index6,
      index7,
      index8,
      index9
    ) {
      this.Level = 1;
      this.form.labelParamList[index1].labelParamChild[index2].labelParamChild[
        index3
      ].labelParamChild[index4].labelParamChild[index5].labelParamChild[
        index6
      ].labelParamChild[index7].labelParamChild[index8].labelParamChild[
        index9
      ].labelParamChild.push({
        associateId: "", //标签之间的关联
        id: null,
        labelName: null,
        labelParamChild: []
      });
    },
    //删除9级下标签
    delet10(
      index1,
      index2,
      index3,
      index4,
      index5,
      index6,
      index7,
      index8,
      index9,
      index10
    ) {
      this.form.labelParamList[index1].labelParamChild[index2].labelParamChild[
        index3
      ].labelParamChild[index4].labelParamChild[index5].labelParamChild[
        index6
      ].labelParamChild[index7].labelParamChild[index8].labelParamChild[
        index9
      ].labelParamChild.splice(index10, 1);
    }
  }
};
</script>
<style lang="less" scoped>
/*里面的代码可以根据自己需求去进行更改*/
/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 6px;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
  border-radius: 50px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background: #ededed;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #ededed;
}

/deep/ .el-icon-circle-plus-outline {
  font-size: 31px;
  vertical-align: middle;
  color: #698eef;
  cursor: pointer;
  padding-left: 10px;
}

/deep/ .general_inputs .el-form-item {
  margin-bottom: 10px;
}

/deep/ .el-icon-remove-outline {
  font-size: 31px;
  vertical-align: middle;
  color: red;
  cursor: pointer;
  padding-left: 10px;
}

::v-deep .el-checkbox-group {
  font-size: 0;
  width: 95%;
}

::v-deep .label_code .el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 16px;
  color: #333333;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}

::v-deep .label_code .el-checkbox__label {
  display: inline-block;
  padding-left: 10px;
  line-height: 19px;
  font-size: 14px;
  color: #333333;
}

::v-deep .essential_information .companyName .el-input {
  width: 596px;
}

::v-deep .essential_information .el-textarea__inner {
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 596px;
  height: 100px;
  font-size: inherit;
  color: #606266;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

::v-deep .essential_information .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 596px;
}

::v-deep .num_input .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 180px;
}

::v-deep .input_flex .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 320px;
}

::v-deep .select_inner .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 41px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 192px;
}

::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 590px;
}

::v-deep .date_picker .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 30px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 596px;
}

::v-deep .name_form .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 596px;
}

::v-deep .cascsder_input .el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 300px;
}

.addlabel {
  background: #ffffff;
  padding: 10px 35px;

  .label_flex {
    display: flex;
    flex-wrap: wrap;
  }

  .po_relive {
    padding-right: 50px;
  }

  .flex_box {
    display: flex;
  }

  .general_inputs {
    overflow: auto;
    padding: 20px 30px 30px 0px;
    border: 1px dashed #c4c4c4;
    border-radius: 2px;
    width: 97%;
    height: 450px;

    .el_inco {
      font-size: 20px;
      color: #146aff;
    }

    .input_flex {
      width: 380px;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .name {
        display: flex;
        align-items: center;
      }

      .inco_flex {
        display: flex;
        align-items: center;
      }

      span {
        padding-left: 15px;
        font-size: 18px;
        cursor: pointer;
      }

      .el_buttom {
        position: relative;
        left: -110px;
      }
    }
  }

  .num_input {
    display: flex;
    flex-direction: column;
  }

  .label_code {
    padding: 30px 0px;
  }

  .child_label {
    background: #f5f6f7;
    padding: 10px 15px;
    display: flex;
    align-items: baseline;
    width: 80%;
  }

  .store_table {
    height: 70px;
    box-sizing: border-box;
    margin: 0 72px 20px 0;
    padding-left: 10px;
    display: flex;

    .store_table_item {
      color: #4e93fb;
      margin-right: 50px;
      height: 100%;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 20px;
    }

    .active {
      color: #4e93fb;
      font-size: 20px;
      border-bottom: 2px solid #4e93fb;
      border-radius: 1px;
    }
  }

  .img_tips {
    position: relative;
    top: -10px;
    color: #6ba1ff;
  }

  .essential_information {
    h4 {
      color: #146aff;
    }
  }

  .label_management {
    padding: 30px 10px;

    .new_page {
      margin: 20px auto;
      width: 100%;
      text-align: center;
    }

    .input-with {
      width: 100%;
      overflow: hidden;
      padding: 20px 0px;

      .input-with-select {
        width: 400px;
        float: right;
      }

      .seach {
        background-color: #448aff;
        color: #fff;
        border-radius: 0;
        border: 1px solid #448aff;
      }
    }
  }

  .dialog-footer {
    border-top: 1px solid #f5f5f5;
    padding-top: 20px;
    display: flex;
    justify-content: center;
  }
}

.number_color {
  width: 80px;
  height: 30px;
  background: #f1f3f6;
  border-radius: 2px;
  text-align: center;
  line-height: 30px;
  font-size: 12px;
  color: #4e93fb;
  position: absolute;
  right: 65px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.flex_right {
  display: flex;
}

.label_number {
  font-size: 14px;
}
</style>
